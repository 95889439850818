<template>
  <div class="containerFull">
    <div class="banner">
      <img src="../../assets/image/gywmBanner.png" alt />
    </div>
    <div class="container">
      <el-row class="tab">
        <el-col :span="8">
          <div @click="tabChange('关于我们')" :class="tabVal=='关于我们'?'active':''">
            <img v-show="tabVal!=='关于我们'" src="../../assets/image/ico_关于我们.png" alt />
            <img v-show="tabVal=='关于我们'" src="../../assets/image/ico_关于我们选中.png" alt />
            <span>关于我们</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div @click="tabChange('资质证书')" :class="tabVal=='资质证书'?'active':''">
            <img v-show="tabVal!=='资质证书'" src="../../assets/image/ico_资质证书.png" alt />
            <img v-show="tabVal=='资质证书'" src="../../assets/image/ico_资质证书选中.png" alt />
            <span>资质证书</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div @click="tabChange('联系我们')" :class="tabVal=='联系我们'?'active':''">
            <img v-show="tabVal!=='联系我们'" src="../../assets/image/ico_联系我们.png" alt />
            <img v-show="tabVal=='联系我们'" src="../../assets/image/ico_联系我们选中.png" alt />
            <span>联系我们</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="containerFull bg">
      <div class="container" v-show="tabVal=='联系我们'">
        <!-- <div class="title">联系方式</div> -->
        <div style="height:25px"></div>
        <el-row>
          <el-col :span="12">
            <div class="lxfs">
              <div style="margin-bottom:20px">
                <img src="../../assets/image/ico_客服热线.png" alt />
                <span>客服热线：0571-81902009（09：00-17：00）</span>
              </div>
              <div style="margin-bottom:20px">
                <img src="../../assets/image/ico_企业邮箱.png" alt />
                <span>企业邮箱：ruiyi@hzreal.cn</span>
              </div>
              <div>
                <img src="../../assets/image/ico_公司地址.png" alt />
                <span>公司地址：杭州市西湖区华星路99号创业大厦B606</span>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="map">
              <amap :zoom="zoom" :center="center" ref="myMap">
                <amap-marker :position="markers" :label="markerLabel"></amap-marker>
              </amap>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="container" v-show="tabVal=='资质证书'">
        <!-- <div class="title">资质证书</div> -->
        <div style="height:25px"></div>
        <!-- <div style="height:360px;margin-bottom:30px">
          <el-carousel :interval="3000" :autoplay="true" height="360px">
            <el-carousel-item v-for="(item, index) in list" :key="index">
              <img class="swiperImg" :src="item.fileUrl" :alt="item.name" :title="item.name" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="container" style="box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);padding:30px">
          <div class="divList" v-for="(item,index) in list" :key="index">
            <el-row>
              <el-col :span="2" style="width:81px">
                <div class="listLeft">
                  <span>{{index+1|indexShow}}</span>
                </div>
              </el-col>
              <el-col :span="22">
                <div style="height:180px">
                  <div
                    style="font-size: 22px;line-height: 70px;opacity: 1;text-align: center;font-weight: bold;"
                  >{{item.name}}</div>
                  <div class="con">
                    <el-row>
                      <el-col :span="2" style="80px">
                        <div style="color: #999999;line-height:30px">获得时间：</div>
                      </el-col>
                      <el-col :span="22">
                        <div style="line-height:30px">{{item.getTime}}</div>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="time" style="position:relative">
                    <el-row>
                      <el-col :span="2" style="80px">
                        <div style="color: #999999;line-height:30px">证书介绍：</div>
                      </el-col>
                      <el-col :span="22">
                        <div
                          style="line-height:30px;width:910px"
                        >{{item.content}}</div>
                      </el-col>
                    </el-row>
                    <div style="position:absolute;right:55px;top:33px;color: #0070FF;">全部</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="block" style="text-align:center;margin:27px 27px 7px 27px; ">
            <el-pagination
              layout="prev, pager, next"
              :total="total"
              :current-page="pageNum"
              :page-size="pageSize"
              @current-change="pageNumChange"
              :hide-on-single-page="true"
              background
            ></el-pagination>
          </div>
        </div>-->
        <el-card>
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in list" :key="index">
              <div class="grid-content bg-purple" align="center">
                <div class="title" style="font-size:20px;font-weight: 400;">{{item.name}}</div>
                <img style="height:180px;width:324px;" :src="item.fileUrl" :alt="item.name" />
              </div>
            </el-col>
          </el-row>
        </el-card>
      </div>
      <div class="container" v-show="tabVal=='关于我们'">
        <!-- <div class="title">关于我们</div> -->
        <div style="height:25px"></div>
        <div class="gywm">
          <div>
            <div
              style="width:52%"
            >杭州瑞懿科技有限公司（简称瑞懿科技），前身是浙江臻善科技的互联网事业部，因业务发展需要于2017年5月独立为杭州瑞懿科技有限公司，瑞懿科技依托多年国土、不动产等行业信息化建设经验，形成覆盖面广、数据链长、权威性强的不动产数据库。为政府、企业、金融机构、社会公众提供 不动产信息咨询、大数据分析、系统研发等不动产信息综合服务。瑞懿科技秉承“深度挖掘数据，努力创造价值，用心服务社会”的使命，力求成为中国权威专业的不动产信息综合服务商。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sysCertList } from "../../api/enterpriseNews.js";
export default {
  data() {
    return {
      tabVal: "关于我们",
      zoom: 17,
      center: [120.121273, 30.278599],
      markers: [120.121273, 30.278599],
      markerLabel: {
        // offset: new AMap.Pixel(10, 10), //设置文本标注偏移量
        content: "<div style='line-height:20px'>创业大厦（华星路）</div>", //设置文本标注内容
        direction: "top" //设置文本标注方位
      },
      total: 0,
      pageNum: 1,
      pageSize: 10,
      list: []
    };
  },
  methods: {
    tabChange(val) {
      this.tabVal = val;
      if (this.tabVal == "资质证书") {
        this.pageNum = 1;
        this.sysCertList();
      }
    },
    pageNumChange(size) {
      console.log(size);
      this.pageNum = size;
      this.sysCertList();
    },
    sysCertList() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      sysCertList(params).then(res => {
        console.log(res.data);
        if (res.data.code == 200) {
          this.list = res.data.data.list;
          this.total = res.data.data.total;
          this.list.forEach(value => {
            value.getTime = value.getTime.split(" ")[0];
          });
        }
        console.log(this.list);
      });
    }
  },
  filters: {
    indexShow(data) {
      console.log(data);
      if (data < 10) {
        return "0" + data;
      } else {
        return data;
      }
    }
  }
};
</script>

<style scoped>
.containerFull {
  width: 100%;
  /* margin-bottom: 40px; */
  /* padding-bottom: 40px; */
}
.banner {
  width: 100%;
  height: 308px;
}
.banner img {
  width: 100%;
  height: 100%;
}
.container {
  /* width: 70%;
  min-width: 1000px; */
  width: 1200px;
  margin: 0 auto;
}
.tab {
  text-align: center;
  height: 40px;
  line-height: 40px;
  position: relative;
  background: #fff;
  top: -20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.tab img {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-right: 10px;
}
.active {
  background: #0070ff;
  color: #ffffff;
}
.title {
  font-size: 30px;
  font-weight: bold;
  color: #00041a;
  text-align: center;
  margin: 40px 0 30px 0;
}
.lxfs {
  box-sizing: border-box;
  height: 320px;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  opacity: 1;
  padding: 100px 0px;
  width: 100%;
}

.lxfs div {
  width: 80%;
  margin: 0 auto;
}

.lxfs img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 10px;
}
.lxfs span {
  font-size: 16px;
  font-weight: 400;
  color: #00041a;
  opacity: 1;
}
.map {
  width: 100%;
  height: 320px;
  background: rgba(0, 0, 0, 0);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  opacity: 1;
}
.gywm {
  width: 100%;
  height: 390px;
  text-indent: 2em;
  box-sizing: border-box;
  
  
  line-height: 35px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  /* margin-bottom: 40px; */
  background-color: #fff;
}
.gywm>div{
  width:100%;
  height:100%;
  padding: 76px 0 0px 40px;
  background: url("../../assets/image/bg_联系我们-关于我们.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.swiperImg {
  width: 100%;
  height: 100%;
}
.divList {
  height: 202px;
  box-sizing: border-box;
  /* padding: 20px 20px 40px 20px; */
  /* display: flex; */
  position: relative;
  margin-bottom: 20px;
  border: 2px solid #ece6e2;
}
.listLeft {
  width: 81px;
  min-width: 81px;
  height: 80px;
  background-image: url("../../assets/image/组 55.png");
  background-position: -1px 0;
  position: relative;
}
.listLeft span {
  position: absolute;
  top: 9px;
  left: 14px;
  font-size: 14px;
  font-weight: bold;
  color: #717171;
}
.bg {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/image/关于我们bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 40px;
}
</style>
<style>
.amap-marker-label {
  border: 1px solid #ccc;
  padding: 3px 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  top: -33px !important;
}
</style>